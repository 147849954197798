<template>
  <header>
    <img class="curve" src="/img/header-curve.svg" />
    <div class="logo"><img src="/img/header-logo.png" /></div>
    <div class="sign" v-if="kwis.id">{{ kwis.locatie }}</div>
    <router-link
      v-if="showProfile"
      title="profyl"
      :to="{ name: 'TeamProfile' }"
      tag="div"
      class="bk-icon bk-icon--pointer right"
    >
      <i class="fa-solid fa-user"></i>
    </router-link>
    <div
        title="Kwis"
        class="bk-icon bk-icon--pointer right"
        v-if="icons.filter((i) => i === 'programma').length"
        @click="kwisStatusRoute()"
    >
      <i class="fa-solid fa-play"></i>
    </div>
  </header>
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: ['locatie', 'icons'],
  computed: {
    ...mapState(['config', 'kwis', 'time']),
    showProfile() {
      return (
        this.icons.filter((i) => i === 'profile').length
      )
    },
  },
  methods: {
    kwisStatusRoute() {
      if (this.kwis.status === 'start') {
        this.$router.push({
          name: 'KwisStart',
          params: { url: this.kwis.url },
        })
      } else if (this.kwis.status === 'programma') {
        this.$router.push({ name: 'KwisProgramma' })
      } else if (this.kwis.status === 'pauze') {
        this.$router.push({ name: 'KwisPauze' })
      } else if (this.kwis.status === 'vraag') {
        this.$router.push({ name: 'KwisProgramma' })
      } else if (this.kwis.status === 'antwoord') {
        this.$router.push({ name: 'KwisProgramma' })
      } else if (this.kwis.status === 'resultaat') {
        this.$router.push({ name: 'KwisProgramma' })
      } else if (this.kwis.status === 'stand') {
        this.$router.push({ name: 'KwisProgramma' })
      } else if (this.kwis.status === 'afgerond') {
        this.$router.push({ name: 'KwisAfgerond' })
      } else {
        //should not happen
        this.$router.push({
          name: 'KwisStart',
          params: { url: this.kwis.url },
        })
      }
    },
  },
}
</script>

<style scoped lang="scss">
header {
  position: relative;
  height: 250px;
  text-align: center;
  width: 100%;
}

.curve {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 250px;
}

.logo {
  position: absolute;
  width: 86%;
  left: 7%;
  bottom: 90px;

  img {
    width: 100%;
  }
}

.sign {
  position: absolute;
  left: 50%;
  bottom: 45px;
  transform: translate(-50%, 0);
  padding: 7px 25px 9px;
  font-size: 23px;
  line-height: 23px;
  font-weight: 400;
  font-family: AmsiProCondensed-Black;
  color: #69b0b0;
  text-transform: uppercase;
  background-color: #fff;
  border-radius: 44px;
  min-width: 236px;
}

.bk-icon {
  position: absolute;
  top: 18px;

  &.right {
    right: 18px;
  }

  &.left {
    left: 18px;
  }
}
</style>

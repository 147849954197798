<template>
  <div class="bk-form">
    <div class="bk-form__title">Ynskriuwe</div>
    <div class="bk-form__text">
      <p>
        Folje dyn email-adres yn en druk op ynskriuwe. Do krigest dan in email mei dêryn in link dy
        't tagong jout ta dizze kwis.
      </p>
    </div>
    <div class="bk-form__group">
      <label for="email" class="bk-form__label">Email-adres</label>
      <input
        type="text"
        class="bk-form__control"
        :class="{ 'is-invalid': form.register.fields.email.error }"
        id="email"
        v-model="register.email"
      />
      <FormFieldMessages
        :messages="form.register.fields.email.error_messages"
      />
    </div>
    <div class="bk-form__actions">
      <FormSubmitButton
        @click.stop="submit()"
        title="Ynskriuwe"
        :submitting="form.register.submitting"
      />
      <KwisLink />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import TeamService from '@/services/TeamService'
import FormFieldMessages from '@/components/FormFieldMessages'
import KwisLink from '@/components/kwis/Link'
import FormSubmitButton from '@/components/FormSubmitButton'

export default {
  components: {
    FormFieldMessages,
    KwisLink,
    FormSubmitButton,
  },
  data() {
    return {
      register: {
        email: '',
      },
      form: {
        register: {
          submitting: false,
          fields: {
            email: { error: false, error_messages: [] },
          },
        },
      },
    }
  },
  computed: {
    ...mapState(['config', 'kwis', 'time']),
  },
  methods: {
    submit() {
      if (this.form.register.submitting) return
      this.form.register.submitting = true
      //reset all validation errors
      let validator = this.form.register.fields
      Object.keys(validator).map(function (objectKey) {
        validator[objectKey].error = false
        validator[objectKey].error_messages = ''
      })
      TeamService.registerRequest(this.register, this.register)
        .then((response) => {
          if (response.data.status === 'ok') {
            if (response.data.message) {
              this.$store.dispatch('notification/add', {
                type: 'success',
                message: response.data.message,
              })
            }
            this.$store.dispatch('kwis/set', true)
          } else {
            if (response.data.validator) {
              let validator = this.form.register.fields
              Object.keys(response.data.validator).map(function (objectKey) {
                validator[objectKey].error = true
                validator[objectKey].error_messages =
                  response.data.validator[objectKey]
              })
            }
          }
          this.form.register.submitting = false
        })
        .catch((error) => {
          console.log(error)
          this.form.register.submitting = false
        })
    },
  },
}
</script>

<template>
  <div class="bk-form__feedback">
    <div
      class="feedback"
      v-for="(message, index) in messages"
      :key="index"
    >
      {{ message }}
    </div>
  </div>
</template>

<script>
export default {
  props: ['messages'],
}
</script>

<style scoped lang="scss">
.bk-form__feedback {
  min-height: 24px;
}
.feedback {
  font-size: .775em;
  color: #dc3545;
}
</style>

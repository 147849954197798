<template>
  <HeaderKlein :icons="[]"/>
  <div class="stand">
    <div class="stand__icon">
      <img src="/img/media-icon.svg" />
    </div>
    <div class="stand__text" v-html="standText">
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import HeaderKlein from "@/components/HeaderKlein";

export default {
  props: ['url'],
  components: {
    HeaderKlein
  },
  computed: {
    ...mapState(['config', 'kwis', 'time']),
    standText() {
      if (this.kwis.team.plaats > 0) {
        return 'Do stiest op it ' + this.kwis.team.plaats + 'e plak<br />'
      } else {
        return 'Tuskenstân wurdt toand<br /> op it grutte skerm'
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.stand {
  margin-top: 120px;

  &__icon {
    margin-top: 45px;
    width: 60%;
    margin-left: 20%;

    img {
      max-width: 100%;
    }
  }

  &__text {
    margin-top: 30px;
    font-weight: 600;
    text-align: center;
  }
}
</style>

<template>
  <div class="bk-form">
    <div class="bk-form__title">{{ titel }}</div>
    <div class="bk-form__text">
      Hjir kinst de namme en de ôfbylding fan dy of dyn team ynstelle.
    </div>
    <div class="bk-form__group">
      <label for="naam" class="bk-form__label">Namme</label>
      <input
        type="text"
        class="bk-form__control"
        :class="{ 'is-invalid': form.profile.fields.naam.error }"
        id="naam"
        v-model="profile.naam"
      />
      <FormFieldMessages :messages="form.profile.fields.naam.error_messages" />
    </div>
    <div class="bk-form__group">
      <label for="afbeelding" class="bk-form__label">Ofbylding</label>
      <div class="afbeelding">
        <Cropper
          v-if="afbeeldingCropper"
          :img="afbeeldingCropper"
          @onCrop="onCrop"
        />
        <div class="afbeelding__preview">
          <div
            class="afbeelding__preview__img"
            v-if="afbeelding"
            @click.stop="$refs.afbeelding.click()"
          >
            <Image :fileName="afbeelding" />
          </div>
        </div>
        <div class="afbeelding__controls">
          <div
            class="bk-btn bk-btn--control bk-btn--fa"
            @click.stop="$refs.afbeelding.click()"
          >
            <i class="fa-solid fa-camera"></i><span>selektearje</span>
          </div>
          <div
            class="bk-btn bk-btn--control bk-btn--fa"
            @click.stop="afbeeldingVerwijderen()"
            v-if="toonAfbeeldingVerwijderen"
          >
            <i class="fa-solid fa-circle-xmark"></i><span>ferwiderje</span>
          </div>
        </div>
      </div>
      <input
        style="display: none"
        id="afbeelding"
        type="file"
        accept="image/*"
        ref="afbeelding"
        @change="afbeeldingGeselecteerd()"
      />
      <FormFieldMessages
        :messages="form.profile.fields.afbeelding.error_messages"
      />
    </div>
    <div class="bk-form__actions">
      <FormSubmitButton
        @click.stop="submit()"
        :title="FormSubmitButtonTitle"
        :submitting="form.profile.submitting"
      />
      <ButtonUnregister v-if="!register" />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import TeamService from '@/services/TeamService'
import FormFieldMessages from '@/components/FormFieldMessages'
import Image from '@/components/Image.vue'
import Cropper from '@/components/Cropper'
import KwisLink from '@/components/kwis/Link'
import FormSubmitButton from '@/components/FormSubmitButton'
import ButtonUnregister from '@/components/team/ButtonUnregister'

export default {
  props: ['register', 'token', 'data'],
  components: {
    ButtonUnregister,
    Cropper,
    FormFieldMessages,
    Image,
    KwisLink,
    FormSubmitButton,
  },
  data() {
    return {
      afbeeldingCropper: null,
      afbeeldingPreview: null,
      afbeeldingRegister: null,
      profile: {
        naam: '',
        afbeelding: '',
        afbeeldingVerwijderen: false,
      },
      form: {
        dirty: false,
        profile: {
          submitting: false,
          fields: {
            naam: { error: false, error_messages: [] },
            afbeelding: { error: false, error_messages: [] },
          },
        },
      },
    }
  },
  computed: {
    ...mapState(['config', 'kwis', 'time']),
    afbeelding() {
      if (this.profile.afbeeldingVerwijderen) {
        return 'team-no-image.png'
      }
      if (this.profile.afbeelding) {
        return this.profile.afbeelding
      }
      if (this.kwis.team.afbeelding) {
        return this.kwis.team.afbeelding
      }
      if (this.afbeeldingRegister) {
        return this.afbeeldingRegister
      }
      return 'team-no-image.png'
    },
    titel() {
      if (this.register) {
        return 'Ynskriuwe'
      }
      return 'Profyl'
    },
    FormSubmitButtonTitle() {
      if (this.register) {
        return 'ynskriuwe'
      }
      return 'opslaan'
    },
    toonAfbeeldingVerwijderen() {
      if (this.profile.afbeeldingVerwijderen) {
        return false
      }
      if (
        this.profile.afbeelding ||
        this.kwis.team.afbeelding ||
        this.afbeeldingRegister
      ) {
        return true
      }
      return false
    },
  },
  methods: {
    onCrop(image) {
      if (!image) {
        this.afbeeldingCropper = null
        return
      }
      this.profile.afbeelding = image
      this.afbeeldingCropper = null
    },
    afbeeldingGeselecteerd() {
      this.afbeeldingCropper = URL.createObjectURL(
        this.$refs.afbeelding.files.item(0)
      )
      this.$refs.afbeelding.value = null
      this.profile.afbeeldingVerwijderen = false
    },
    afbeeldingVerwijderen() {
      this.profile.afbeeldingVerwijderen = true
      this.profile.afbeelding = ''
      this.$refs.afbeelding.value = null
      this.afbeeldingPreview = 'team-no-image.png'
    },
    submit() {
      if (this.form.profile.submitting) return
      this.form.profile.submitting = true

      //reset all validation errors
      let validator = this.form.profile.fields
      Object.keys(validator).map(function (objectKey) {
        validator[objectKey].error = false
        validator[objectKey].error_messages = ''
      })
      TeamService.profile(this.profile, this.register, this.token)
        .then((response) => {
          if (response.data.status === 'ok') {
            if (response.data.message) {
              this.$store.dispatch('notification/add', {
                type: 'success',
                message: response.data.message,
              })
            }
            this.$store.dispatch('kwis/set', true)
          } else {
            if (response.data.validator) {
              let validator = this.form.profile.fields
              Object.keys(response.data.validator).map(function (objectKey) {
                validator[objectKey].error = true
                validator[objectKey].error_messages =
                  response.data.validator[objectKey]
              })
            }
          }
          this.form.profile.submitting = false
        })
        .catch((error) => {
          console.log(error)
          this.form.profile.submitting = false
        })
    },
  },
  mounted() {
    if (this.register) {
      this.profile.naam = this.data.naam
      this.afbeeldingRegister = this.data.afbeelding
    } else {
      this.profile.naam = this.kwis.team.naam
    }
  },
}
</script>

<style scoped lang="scss">
.afbeelding {
  display: flex;
  background-color: #ffffff;
  border-radius: 12px;
  padding: 20px;

  &__preview {
    width: 65px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &__img {
      height: 65px;
      width: 65px;
      border-radius: 50%;
      overflow: hidden;
      cursor: pointer;

      img {
        max-height: 100%;
        border-radius: 50%;
      }
    }
  }

  &__controls {
    margin-left: 30px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;

    div {
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
</style>

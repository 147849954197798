<template>
  <div class="loading">
    <div class="spinner-border" role="status"></div>
    <div class="mt-2">Scan de QR-code om diel te nimmen.</div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'Home',
  computed: {
    ...mapState(['config', 'kwis', 'time']),
  },
  mounted() {
    if (this.kwis.id) {
      this.$router.push({
        name: 'KwisStart',
        params: { url: this.kwis.url },
      })
      return
    }
  },
}
</script>

<style scoped lang="scss">
.loading {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background-color: #ffeb94;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .spinner-border {
    color: #ffcc4e;
  }
}
</style>

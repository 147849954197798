import axios from 'axios'
import router from "../router";

const apiClient = axios.create({
  baseURL: process.env.VUE_APP_HOST_API + '/api/team',
  withCredentials: true,
  headers: {
    Accept: 'application/json',
  },
})

apiClient.interceptors.response.use(
    (response) => {
      return response
    },
    (error) => {
      console.log(error.response.status)
      if (error.response.status == 401) {
        console.log('error.response.status == 401')
        router.push({ name: 'TeamRegisterRequest' })
      }
      return Promise.reject(error)
    }
)

export default {
  registerRequest(data) {
    return apiClient.post('/register-request', data)
  },
  registerRequestToken(data) {
    return apiClient.post('/register-request-token', data)
  },
  profile(data, register, token) {
    console.log(data)
    let formData = new FormData()
    if (register) {
      formData.append('token', token)
    }
    formData.append('naam', data.naam)
    formData.append('afbeelding', data.afbeelding)
    formData.append('afbeeldingVerwijderen', data.afbeeldingVerwijderen ? 1 : 0)
    if (register) {
      return apiClient.post('/register', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
    }
    return apiClient.post('/profile', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  },
  unregister() {
    return apiClient.post('/unregister')
  },
}

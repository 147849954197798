import axios from 'axios'

const apiClient = axios.create({
  baseURL: process.env.VUE_APP_HOST_API + '/api/team/kwis',
  withCredentials: true,
  headers: {
    Accept: 'application/json',
  },
})

export default {
  loginUrl(url) {
    return apiClient.post('/login-url', { url })
  },
  get() {
    return apiClient.get('')
  },
  antwoordConfirm(data) {
    return apiClient.post('/antwoord/confirm', data)
  },
  antwoord(data) {
    return apiClient.post('/antwoord', data)
  },
}

<template>
  <Loader v-if="!vraag.id" />
  <div class="vraag" :class="vraagAntwoordenClass" v-else>
    <div class="vraag__header" :style="categorieKleurStyle" v-if="showVraag">
      <div class="vraag__header__titel">
        Fraach {{ vraag.nummer }}/{{ vraag.totaal }}
      </div>
      <div class="vraag__header__categorie">
        <div class="vraag__header__categorie__afbeelding">
          <Image :fileName="vraag.categorie.afbeelding" />
        </div>
        <div class="vraag__header__categorie__titel">
          {{ vraag.categorie.titel }}
        </div>
      </div>
      <div class="vraag__header__pagina">
        {{ vraag.nummer }}/{{ vraag.totaal }}
      </div>
    </div>
    <div class="vraag__media" v-if="showVraag && showMedia">
      <div class="vraag__media__icon">
        <img src="/img/media-icon.svg" />
      </div>
      <div class="vraag__media__text">
        Filmke wurdt ôfspile<br />op it grutte skerm!
      </div>
    </div>
    <div class="vraag__content" v-if="showVraag && showContent">
      <div class="vraag__content__tekst">
        {{ vraag.titel }}
      </div>
      <div v-if="false" class="vraag__content__afbeelding">
        <Image :fileName="vraag.afbeelding" />
      </div>
    </div>
    <div class="vraag__antwoorden" v-if="showVraag && showAntwoorden">
      <Antwoord
        v-for="antwoord in vraag.antwoorden"
        :key="'antwoord-' + antwoord.id"
        :antwoord="antwoord"
        :timelineDurationSeconds="timelineDurationSeconds"
        :timelineProgressSeconds="timelineProgressSeconds"
        :color="vraag.categorie.kleur"
      />
    </div>
    <div class="vraag__resultaatinfo" v-if="showResultaatinfo">
      <div class="resultaatinfo" :style="categorieKleurStyle">
        <div class="resultaatinfo__titel">DE ANTWURDEN!</div>
        <div class="resultaatinfo__afbeelding">
          <Image :fileName="vraag.categorie.afbeelding" />
        </div>
        <div class="resultaatinfo__counter" v-if="showResultaatinfoCounter">
          <TimeCounter
            :timelineDurationSeconds="timelineDurationSeconds"
            :timelineProgressSeconds="timelineProgressSeconds"
          />
        </div>
      </div>
    </div>
    <div class="vraag__footer">
      <Timeline
        v-if="showTimeline"
        :timelineDurationSeconds="timelineDurationSeconds"
        :timelineProgressSeconds="timelineProgressSeconds"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Image from '@/components/Image.vue'
import Antwoord from '@/components/kwis/Antwoord'
import Timeline from '@/components/Timeline'
import Loader from '@/components/Loader'
import TimeCounter from '@/components/TimeCounter'

export default {
  props: [],
  components: {
    TimeCounter,
    Antwoord,
    Image,
    Timeline,
    Loader,
  },
  data() {
    return {
      timelineInterval: null,
      timelineDurationSeconds: 0,
      timelineProgressSeconds: 0,
      showResultaatinfoCounter: false,
    }
  },
  computed: {
    ...mapState(['config', 'kwis', 'time']),
    vraag() {
      return this.kwis.vraag
    },
    vraagIdAndStatus() {
      return this.vraag.id + this.vraag.status
    },
    showVraag() {
      return (
        this.kwis.status === 'media' ||
        this.kwis.status === 'vraag' ||
        this.kwis.status === 'antwoord' ||
        this.kwis.status === 'resultaat'
      )
    },
    showResultaatinfo() {
      return (
        this.kwis.status === 'resultaatinfo'
      )
    },
    showMedia() {
      return this.kwis.status === 'media'
    },
    showContent() {
      return this.kwis.status !== 'media'
    },
    showAntwoorden() {
      return this.vraag.antwoorden
    },
    embeddedMedia() {
      if (!this.kwis.vraag.video_url) {
        return '<h2>Fideo net beskikber.</h2>'
      }
      return (
        '<iframe src="https://www.youtube.com/embed/' +
        this.kwis.vraag.video_url +
        '?controls=0&autoplay=1" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" ></iframe>'
      )
    },
    categorieKleurStyle() {
      return {
        'background-color': '#' + this.vraag.categorie.kleur,
        color: '#ffffff',
      }
    },
    showTimeline() {
      if (!this.timelineDurationSeconds) {
        return false
      }
      return (
        this.vraag.status === 'media' ||
        this.vraag.status === 'vraag' ||
        this.vraag.status === 'antwoord'
      )
    },
    vraagAntwoordenClass() {
      return this.vraag.categorie.class
    },
  },
  methods: {
    getProgressSeconds(durationSeconds, progressSeconds) {
      if (progressSeconds > durationSeconds) {
        return durationSeconds
      }
      if (0 > progressSeconds) {
        return 0
      }
      return progressSeconds
    },
  },
  watch: {
    vraagIdAndStatus: {
      immediate: true,
      handler(newVal, oldVal) {
        this.showResultaatinfoCounter = false
        if (!this.vraag.id) return
        if (this.vraag.status === 'media') {
          this.timelineDurationSeconds = this.vraag.mediaSeconden
          this.timelineProgressSeconds = this.getProgressSeconds(
            this.vraag.mediaSeconden,
            this.time.timestamp - this.vraag.mediaStartAt
          )
        } else if (this.vraag.status === 'vraag') {
          this.timelineDurationSeconds = this.vraag.vraagSeconden
          this.timelineProgressSeconds = this.getProgressSeconds(
            this.vraag.vraagSeconden,
            this.time.timestamp - this.vraag.vraagStartAt
          )
        } else if (this.vraag.status === 'antwoord') {
          this.timelineDurationSeconds = this.vraag.antwoordSeconden
          this.timelineProgressSeconds = this.getProgressSeconds(
            this.vraag.antwoordSeconden,
            this.time.timestamp - this.vraag.antwoordStartAt
          )
        } else if (this.vraag.status === 'resultaatinfo') {
          this.timelineDurationSeconds = this.vraag.resultaatinfoSeconden
          this.timelineProgressSeconds = this.getProgressSeconds(
            this.vraag.resultaatinfoSeconden,
            this.time.timestamp - this.vraag.resultaatinfoStartAt
          )
          this.showResultaatinfoCounter = true
        } else if (this.vraag.status === 'resultaat') {
          this.timelineDurationSeconds = this.vraag.resultaatSeconden
          this.timelineProgressSeconds = this.getProgressSeconds(
            this.vraag.resultaatSeconden,
            this.time.timestamp - this.vraag.resultaatStartAt
          )
        } else {
          return
        }
        clearInterval(this.timelineInterval)
        this.timelineInterval = setInterval(() => {
          if (this.timelineProgressSeconds < this.timelineDurationSeconds) {
            this.timelineProgressSeconds++
          } else {
            clearInterval(this.timelineInterval)
          }
        }, 1000)
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.vraag {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(740px - 180px);
  padding: 40px 25px;

  &__header {
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    height: 50px;

    &__titel {
      font-family: AmsiProCondensed-Black;
      color: #ffffff;
      font-size: 28px;
      line-height: 28px;
      padding: 10px 19px 12px;
      text-transform: uppercase;
    }

    &__categorie {
      display: flex;
      font-family: AmsiProCondensed-Black;
      color: #ffffff;
      font-size: 30px;

      &__afbeelding {
        margin-top: -18px;
        height: 86px;

        img {
          max-height: 100%;
        }
      }

      &__titel {
        display: none;
      }
    }

    &__pagina {
      display: none;
      color: #ffffff;
      font-size: 30px;
    }
  }

  &__media {
    margin-top: 40px;

    &__icon {
      margin-top: 45px;
      width: 60%;
      margin-left: 20%;

      img {
        max-width: 100%;
      }
    }

    &__text {
      margin-top: 30px;
      font-weight: 600;
      text-align: center;
    }
  }

  &__content {
    margin-top: 40px;
    display: flex;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;

    &__afbeelding {
      height: 80px;

      img {
        max-height: 100%;
      }
    }
  }

  &__antwoorden {
    display: flex;
    flex-wrap: wrap;
    margin-top: 40px;
  }

  &__footer {
    position: relative;
    flex-grow: 1;
    display: flex;
    align-items: flex-end;
    width: 100%;
  }
}

.timeline {
  height: 12px;
  background-color: #fff5c9;
  border-radius: 6px;
  overflow: hidden;
  width: 100%;

  &__progress {
    height: 100%;
    background-color: #ffcc4e;
    width: 0;
  }
}

.resultaatinfo {
  position: relative;
  width: 331px;
  height: 331px;
  border-radius: 22px;
  margin: 50px auto 0;

  &__titel {
    text-align: center;
    font-family: AmsiProCondensed-Black;
    font-size: 33px;
    line-height: 33px;
    padding-top: 47px;
  }

  &__afbeelding {
    height: 165px;
    margin-top: 27px;
    text-align: center;

    img {
      height: 100%;
    }
  }

  &__counter {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 50%);
  }
}
</style>

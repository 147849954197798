<template>
  <div class="vraag">
    <HeaderKlein :icons="[]" />
    <Vraag />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Vraag from '@/components/kwis/Vraag'
import HeaderKlein from '@/components/HeaderKlein'

export default {
  components: {
    HeaderKlein,
    Vraag,
  },
  computed: {
    ...mapState(['config', 'kwis', 'time']),
  },
}
</script>

<style lang="scss" scoped></style>

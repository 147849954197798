<template>
  <div class="loader" v-if="!loaded">
    <Loader />
  </div>
  <div v-else>
    <router-view />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import SanctumService from '@/services/SanctumService'
import Loader from '@/components/Loader'

export default {
  components: { Loader },
  computed: {
    ...mapState(['config']),
    loaded() {
      return this.config.loaded
    },
  },
  created() {
    //socket.io
    // window.ws.connector.socket.on('connect', (data) => {
    //   console.log('ws connected', window.ws.socketId())
    //   this.$store.dispatch('network/setWebsockets', true)
    //   this.$store.dispatch('network/setSocketId', window.ws.socketId())
    // })
    // window.ws.connector.socket.on('disconnect',  (data) => {
    //   console.log('ws disconnected')
    //   this.$store.dispatch('network/setWebsockets', false)
    //   this.$store.dispatch('network/setSocketId', null)
    // })
    // window.ws.connector.socket.on('reconnecting',  (attemptNumber) => {
    //   console.log('ws reconnecting', attemptNumber)
    // })

    //pusher
    this.ws.connector.pusher.connection.bind('connected', (data) => {
      console.log('ws connected', data.socket_id)
      this.$store.dispatch('network/setWebsockets', true)
      this.$store.dispatch('network/setSocketId', data.socket_id)
    })
    this.ws.connector.pusher.connection.bind('disconnected', () => {
      console.log('ws disconnected')
      this.$store.dispatch('network/setWebsockets', false)
      this.$store.dispatch('network/setSocketId', null)
    })
    this.ws.connector.pusher.connection.bind('connecting', () => {
      console.log('ws disconnected')
      this.$store.dispatch('network/setWebsockets', false)
      this.$store.dispatch('network/setSocketId', null)
    })
    this.ws.connector.pusher.connection.bind('unavailable', () => {
      console.log('ws disconnected')
      this.$store.dispatch('network/setWebsockets', false)
      this.$store.dispatch('network/setSocketId', null)
    })
    this.ws.connector.pusher.connection.bind('state_change', function (states) {
      console.log('ws states.current', states.current)
      if (states.current === 'disconnected') {
        // if (localStorage.getItem('pusherTransportNonTLS')) {
        //   localStorage.removeItem('pusherTransportNonTLS')
        //   location.href = '/kwismaster/programma'
        //   return
        // }
        // this.ws.connector.pusher.connect()
        //this.$store.dispatch('network/setWebsockets', true)
      }
    })
  },
}
</script>

<style lang="scss" scoped>
.loader {
  height: 100vh;
}
</style>

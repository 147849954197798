<template>
  <Header :icons="['profile']" />
  <div class="afgerond">
    <div v-if="!showPrijs">
      <div class="afgerond__icon">
        <img src="/img/media-icon.svg" />
      </div>
      <div class="afgerond__text">
        Utslach wurdt toand<br />
        op it grutte skerm
      </div>
    </div>
    <div v-else>
      <div class="afgerond__titel">Topper!</div>
      <div class="afgerond__text">
        Do hast it {{ kwis.team.plaats }}e<br />plak helle!
      </div>
      <div class="afgerond__prijs">
        <img v-if="imagePrijs" :src="imagePrijs" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Header from '@/components/Header'

export default {
  props: ['url'],
  components: {
    Header,
  },
  computed: {
    ...mapState(['kwis']),
    showPrijs() {
      return this.kwis.team.plaats > 0
    },
    imagePrijs() {
      if (this.kwis.team.plaats === 1) {
        return '/img/beker-goud.jpg'
      }
      if (this.kwis.team.plaats === 2) {
        return '/img/beker-zilver.jpg'
      }
      if (this.kwis.team.plaats === 3) {
        return '/img/beker-brons.jpg'
      }
      return '/img/medaille.jpg'
    },
  },
}
</script>

<style lang="scss" scoped>
.afgerond {
  text-align: center;

  &__icon {
    margin-top: 45px;
    width: 60%;
    margin-left: 20%;

    img {
      max-width: 100%;
    }
  }

  &__text {
    margin-top: 30px;
    font-weight: 600;
    text-align: center;
  }

  &__titel {
    margin-top: 30px;
    font-family: Chango;
    color: #dc0c15;
    font-size: 40px;
    line-height: 48px;
  }

  &__text {
    margin-top: 20px;
    font-weight: 500;
    font-size: 22px;
    line-height: 30px;
  }

  &__prijs {
    margin: 5px 0 30px;
    height: 250px;

    img {
      height: 100%;
    }
  }
}
</style>

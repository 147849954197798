<template>
  <router-link
    class="bk-btn bk-btn--secondary"
    title="Kwis"
    v-if="kwis.id"
    :to="kwisStatusRoute()"
    tag="div"
  >
    Nei kwis ta
  </router-link>
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState(['kwis']),
  },
  methods: {
    kwisStatusRoute() {
      if (this.kwis.team.id) {
        //subscribed
        if (this.kwis.status === 'start') {
          return {
            name: 'KwisStart',
            params: { url: this.kwis.url },
          }
        } else if (this.kwis.status === 'programma') {
          return { name: 'KwisProgramma' }
        } else if (this.kwis.status === 'vraag') {
          return { name: 'KwisProgramma' }
        } else if (this.kwis.status === 'antwoord') {
          return { name: 'KwisProgramma' }
        } else if (this.kwis.status === 'resultaat') {
          return { name: 'KwisProgramma' }
        } else if (this.kwis.status === 'stand') {
          return { name: 'KwisProgramma' }
        } else if (this.kwis.status === 'afgerond') {
          return { name: 'KwisAfgerond' }
        }
        return {
          name: 'KwisStart',
          params: { url: this.kwis.url },
        }
      }
      //not subscribed
      return {
        name: 'KwisStart',
        params: { url: this.kwis.url },
      }
    },
  },
}
</script>

<style scoped lang="scss"></style>

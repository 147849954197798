<template>
  <div class="programma__container" v-if="kwis.programma">
    <div class="programma"><div class="programma__titel">Programma:</div></div>
    <div
      class="programma"
      :class="programmaClass(programma)"
      :style="programmaStyle(programma)"
      v-for="programma in kwis.programma"
      :key="'programma-' + programma.id"
    >
      <div class="programma__afbeelding" v-if="programma.categorie.afbeelding">
        <Image :fileName="programma.categorie.afbeelding" />
      </div>
      <div class="programma__naam" :title="programma.categorie.naam">
        {{ programma.categorie.naam }}
      </div>
      <div class="programma__afgerond bk-shadow"></div>
    </div>
  </div>
</template>

<script>
import Image from '@/components/Image.vue'
import { mapState } from 'vuex'

export default {
  props: [],
  components: {
    Image,
  },
  computed: {
    ...mapState(['config', 'kwis', 'time']),
  },
  methods: {
    programmaClass(programma) {
      return {
        'programma--categorie': !programma.categorie.pauze,
        'programma--titel': programma.categorie.pauze,
        'programma--afgerond': programma.finished,
      }
    },
    programmaStyle(programma) {
      return {
        'background-color': '#' + programma.categorie.kleur,
      }
    },
  },
  mounted() {
    if (!this.kwis.id) {
      this.$router.push({ name: 'Home' })
    }
  },
}
</script>

<style lang="scss" scoped>
.programma {
  position: relative;
  display: flex;
  background-color: #ffffff;
  border-radius: 13px;
  margin-bottom: 30px;
  color: #000000;
  font-family: AmsiProCondensed-Black;
  font-size: 24px;
  line-height: 24px;
  padding: 8px 0 11px;

  &__container {
    padding: 40px 25px;
  }

  &--categorie {
    color: #ffffff;
    font-size: 30px;
    line-height: 30px;
    padding: 13px 0 17px;
    text-transform: uppercase;

    .programma__naam {
      margin-left: 108px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      text-align: left;
    }
  }

  &__titel {
    flex-grow: 1;
    text-align: center;
    margin-left: 0;
  }

  &--afgerond {
    .programma__afgerond {
      opacity: 1;
    }
  }

  &__naam {
    flex-grow: 1;
    text-align: center;
    margin-left: 0;
    text-transform: uppercase;
  }

  &__afgerond {
    opacity: 0;
    position: absolute;
    height: 32px;
    width: 32px;
    border-radius: 50%;
    background-color: #ffffff;
    top: 50%;
    transform: translateY(-50%);
    right: 16px;
    background-image: url('/img/afgerond.png');
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: 70%;
    transition: opacity 400ms;

    &:after {
      content: ' ';
    }
  }

  &__afbeelding {
    position: absolute;
    top: -9px;
    left: 10px;
    height: 78px;
    text-align: center;

    img {
      height: 100%;
    }
  }
}
</style>

<template>
  <div class="register">
    <Header :icons="[]" />
    <FormProfile v-if="tokenValid" :register="true" :token="token" :data="data" />
    <div class="bk-form" v-if="tokenValid === false">
      <div class="bk-form__title">Ynskriuwe</div>
      <div>
        <p>{{ errorMessage }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import FormProfile from '@/components/team/FormProfile'
import Header from '@/components/Header'
import TeamService from "@/services/TeamService";

export default {
  props: ['token'],
  components: {
    FormProfile,
    Header,
  },
  data() {
    return {
      tokenValid: null,
      errorMessage: '',
      data: null,
    }
  },
  computed: {
    ...mapState(['config', 'kwis', 'time']),
  },
  created() {
    TeamService.registerRequestToken({ token: this.token })
      .then((response) => {
        if (response.data.status == 'ok') {
          if (response.data.loggedIn) {
            this.$store.dispatch('kwis/set', true)
            return
          }
          this.tokenValid = true
          this.data = response.data.data.register
        } else {
          this.tokenValid = false
          this.errorMessage = response.data.message;
        }
      })
      .catch((error) => {
        console.log(error)
      })
  },
}
</script>

<style lang="scss" scoped>
.register {
}
</style>

<template>
  <div class="register">
    <Header :icons="[]"/>
    <FormRegisterRequest />
  </div>
</template>

<script>
import FormRegisterRequest from '@/components/team/FormRegisterRequest'
import Header from '@/components/Header'

export default {
  props: [],
  components: {
    FormRegisterRequest,
    Header,
  },
}
</script>

<template>
  <modal ref="modal">
    <div class="confirm-dialog">
      <div class="confirm-dialog__title">
        {{ title }}
      </div>
      <div class="confirm-dialog__text">
        {{ message }}
      </div>
      <div class="confirm-dialog__controls">
        <div class="bk-btn bk-btn--control" @click="cancel">{{ cancelButton }}</div>
        <div class="bk-btn bk-btn--control" @click="confirm">{{ confirmButton }}</div>
      </div>
    </div>
  </modal>
</template>

<script>
import Modal from '@/components/Modal.vue'

export default {
  name: 'ConfirmDialogue',
  components: { Modal },
  data: () => ({
    title: undefined,
    message: undefined,
    confirmButton: undefined,
    cancelButton: 'Nee',
    resolvePromise: undefined,
    rejectPromise: undefined,
  }),
  methods: {
    show(opts = {}) {
      this.title = opts.title
      this.message = opts.message
      this.confirmButton = opts.confirmButton
      if (opts.cancelButton) {
        this.cancelButton = opts.cancelButton
      }
      this.$refs.modal.open()
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve
        this.rejectPromise = reject
      })
    },
    confirm() {
      this.$refs.modal.close()
      this.resolvePromise(true)
    },
    cancel() {
      this.$refs.modal.close()
      this.resolvePromise(false)
    },
  },
}
</script>

<style lang="scss" scoped>
.confirm-dialog {
  text-align: center;
  padding: 0 20px;

  &__title {
    padding: 20px 0 0;
    font-family: AmsiProCondensed-Black;
    font-size: 32px;
    line-height: 36px;
  }

  &__text {
    padding: 22px 0 0;
    font-size: 22px;
    line-height: 30px;
  }

  &__controls {
    padding: 20px 0 10px;
    display: flex;
    justify-content: space-around;

    .bk-btn {
      margin-left: 0;
    }
  }
}
</style>

<template>
  <Header :locatie="kwis.locatie" :icons="headerIcons" />
  <div class="start">
    <div class="title">Wolkom!</div>
    <div class="text">
      <p>Doch mei oan de leukste Fryske kroechkwis fan 'e wrâld! Test dyn kennis fan it Frysk tegearre mei dyn freonen, buorlju, kollega's, omkes en muoikes of dyn folsleine sportteam!</p>
      <p>Wa makket de minste flaters en is de winner fan de Fryske Kroechkwis fan <b>{{ kwis.plaats }}</b>?</p>
      <p><b>In soad wille en sukses!</b></p>
    </div>
    <div class="subscribe" v-if="canSubscribe && !subscribed">
      <router-link class="bk-btn" :to="{ name: 'TeamRegisterRequest' }"
        >Ynskriuwe</router-link
      >
    </div>
    <div class="subscribe" v-if="!canSubscribe">
      Ynskriuwe net mooglik, kwis is ôfrûne.
    </div>
    <div class="subscribe" v-if="subscribed">
      <div>
        Ynskreaun as:<br /><b>{{ kwis.team.naam }}</b>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import KwisService from '@/services/KwisService'
import Header from '@/components/Header'
import LinkProfile from '@/components/team/LinkProfile'
import ButtonUnregister from '@/components/team/ButtonUnregister'

export default {
  props: ['url'],
  components: {
    ButtonUnregister,
    LinkProfile,
    Header,
  },
  computed: {
    ...mapState(['config', 'kwis', 'time']),
    secondsBeforeStart() {
      let startAtTimestamp = this.$timestamp(this.$date(this.kwis.startOp))
      let secondsBeforeStart = startAtTimestamp - this.time.timestamp
      if (secondsBeforeStart < 1) secondsBeforeStart = 0
      return secondsBeforeStart
    },
    subscribed() {
      return this.kwis.team.id ? true : false
    },
    canSubscribe() {
      if (this.kwis.status === 'afgerond') {
        return false
      }
      return true
    },
    headerIcons() {
      if (this.subscribed) {
        return ['profile']
      }
      return []
    }
  },
  created() {
    if (this.subscribed) {
      this.$router.push({ name: 'Loader' })
      return
    }
    KwisService.loginUrl(this.url)
      .then((response) => {
        if (response.data.status == 'ok') {
          this.$store.dispatch('kwis/set', false)
        } else {
          this.$router.push({ name: 'Home' })
        }
      })
      .catch((error) => {
        console.log(error)
      })
  },
}
</script>

<style lang="scss" scoped>
.links {
  text-align: center;
}
.start {
  text-align: center;
  padding-bottom: 30px;
  height: 100%;

  .title {
    margin-top: 30px;
    font-family: Chango;
    color: #dc0c15;
    font-size: 40px;
    line-height: 48px;
  }

  .text {
    font-family: 'Roboto';
    font-weight: 500;
    margin-top: 25px;
    padding: 0 50px;
    font-weight: 500;

    p {
      margin-bottom: 15px;
    }
  }

  .subtitle {
    font-family: 'Roboto';
    font-weight: 700;
    margin-top: 25px;
    font-size: 18px;
    line-height: 24px;
  }

  .counter {
    font-family: 'Roboto';
    font-weight: 500;
    margin-top: 25px;
    font-size: 18px;
    line-height: 24px;
  }

  .subscribe {
    margin-top: 25px;
  }
}
</style>

<template>
  <div class="loader">
    <Header :icons="headerIcons" />
    <div class="loading">
      <img src="img/spinner.gif" />
      <div class="loading__text" v-html="text"></div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Header from "@/components/Header";

export default {
  components: {
    Header,
  },
  computed: {
    ...mapState(['config', 'kwis', 'time']),
    subscribed() {
      return this.kwis.team.id ? true : false
    },
    headerIcons() {
      if (this.subscribed) {
        return ['profile']
      }
      return []
    },
    text() {
      return this.kwis.status === 'start' ? 'Noch eefkes wachtsje, de kroechkwis begjint hast' : 'Efkes geduld...<br>De kwis giet sa fierder!'
    }
  },
}
</script>

<style lang="scss" scoped>
.loader {
  height: 100vh;
}

.loading {
  width: 100%;
  height: calc(85vh - 250px);
  background-color: #ffeb94;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  img {
    max-width: 60%;
  }

  &__text {
    text-align: center;
    font-weight: 500;
  }
}
</style>

<template>
  <img v-if="fileName" :src="src" />
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: ['fileName'],
  computed: {
    ...mapState(['config']),
    src() {
      if (this.fileName.includes('http')) {
        return this.fileName
      }
      if (this.fileName.includes('data:image')) {
        return this.fileName
      }
      return this.$store.state.config.urlStorage + '/image/' + this.fileName
    },
  },
}
</script>

<style scoped lang="scss"></style>

<template>
  <div class="wrapper">
    <div class="scale">
      <router-view />
      <SystemTray />
      <NotificationContainer />
    </div>
  </div>
</template>

<script>
import NotificationContainer from '@/components/notification/NotificationContainer'
import SystemTray from '@/components/SystemTray'

export default {
  components: { NotificationContainer, SystemTray },
  data() {
    return {
      wrapperHeight: 0,
      wrapperWidth: 0,
      wrapperAspectRatio: 0,
      scaleHeight: 740,
      scaleWidth: 360,
    }
  },
  computed: {
    scaleAspectRatio() {
      return this.scaleWidth / this.scaleHeight
    },
    wrapperStyle() {
      let zoom
      if (this.wrapperAspectRatio < this.scaleAspectRatio) {
        //console.log('A')
        //breedte 100%
        zoom = this.wrapperWidth / this.scaleWidth
      } else {
        //console.log('B')
        //hoogte 100%
        zoom = this.wrapperHeight / this.scaleHeight
      }
      //console.log('zoom', zoom)
      return {
        width: this.scaleWidth + 'px',
        height: this.scaleHeight + 'px',
        zoom: zoom,
      }
    },
  },
  methods: {
    handleResize() {
      this.wrapperHeight = window.innerHeight
      this.wrapperWidth = window.innerWidth
      this.wrapperAspectRatio = window.innerWidth / window.innerHeight
    },
  },
  created() {
    // window.addEventListener('resize', this.handleResize)
    // this.handleResize()
  },
  unmounted() {
    // window.removeEventListener('resize', this.handleResize)
  },
}
</script>

<style lang="scss" scoped>
.wrapper {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  height: 100%;
  min-height: 100vh;
}

.scale {
  position: relative;
  background-color: #ffeb94;
  align-self: center;
  position: relative;
  width: 100%;
  max-width: 450px;
  height: 100%;
  min-height: 100vh;
}
</style>

<template>
  <modal ref="modal">
    <div class="cropper">
      <cropper
        class="cropper"
        :src="img"
        :stencil-props="{
          handlers: {},
          movable: false,
          scalable: false,
        }"
        :stencil-size="{
          width: 200,
          height: 200,
        }"
        image-restriction="stencil"
        :stencil-component="$options.components.CircleStencil"
        :canvas="{
          height: 200,
          width: 200,
        }"
        @change="change"
        ref="cropper"
      />
    </div>
    <div class="cropper__controls">
      <div class="bk-btn bk-btn--control" @click="close">Annulearje</div>
      <div class="bk-btn bk-btn--control" @click="crop">Bysnije</div>
    </div>
  </modal>
</template>

<script>
import Modal from '@/components/Modal.vue'
import { CircleStencil, Cropper } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css'

export default {
  props: ['img'],
  components: {
    Modal,
    Cropper,
    CircleStencil,
  },
  data() {
    return {
      image: null,
    }
  },
  methods: {
    change({ coordinates, canvas }) {
      //console.log(coordinates, canvas)
      //this.image = canvas.toDataURL()
    },
    crop() {
      const { coordinates, canvas } = this.$refs.cropper.getResult()
      this.coordinates = coordinates
      // You able to do different manipulations at a canvas
      // but there we just get a cropped image, that can be used
      // as src for <img/> to preview result
      this.image = canvas.toDataURL()
      this.$emit('onCrop', this.image)
      this.$refs.modal.close()
    },
    close() {
      this.$emit('onCrop', null)
      this.$refs.modal.close()
    },
  },
  mounted() {
    this.$refs.modal.open()
  }
}
</script>

<style scoped lang="scss">
.cropper {
  height: 200px;
  width: 100%;
  background: #ffffff;

  &__controls {
    padding: 10px;
    display: flex;
    justify-content: space-around;

    .bk-btn {
      margin-left: 0;
    }
  }
}
</style>

import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import functions from './plugins/functions'
import './assets/styles/custom.scss'
import axios from 'axios'
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"
import Echo from 'laravel-echo'
const app = createApp(App)

//socket.io
// window.io = require('socket.io-client')
// let ws = new Echo({
//   broadcaster: 'socket.io',
//   host: process.env.VUE_APP_HOST_WS + ':' + process.env.VUE_APP_PORT_WS,
// })

//pusher.com
window.Pusher = require('pusher-js')
let ws = new Echo({
  broadcaster: 'pusher',
  key: process.env.VUE_APP_PUSHER_KEY,
  cluster: process.env.VUE_APP_PUSHER_CLUSTER,
  encrypted: true,
  authorizer: (channel) => {
    return {
      authorize: (socketId, callback) => {
        axios({
          method: 'POST',
          url: process.env.VUE_APP_HOST_API + '/api/broadcasting/auth',
          withCredentials: true,
          data: {
            socket_id: socketId,
            channel_name: channel.name,
          },
        })
          .then((response) => {
            callback(false, response.data)
          })
          .catch((error) => {
            //console.log('Echo authorize error', error)
            callback(true, error)
          })
      },
    }
  },
})

//laravel-websockets
// window.Pusher = require('pusher-js')
// let ws = new Echo({
//   broadcaster: 'pusher',
//   key: process.env.VUE_APP_PUSHER_KEY,
//   wsHost: process.env.VUE_APP_HOST_WS,
//   wssHost: process.env.VUE_APP_HOST_WS,
//   wsPort: process.env.VUE_APP_PORT_WS,
//   wssPort: process.env.VUE_APP_PORT_WS,
//   cluster: process.env.VUE_APP_PUSHER_CLUSTER,
//   forceTLS: true,
//   disableStats: true,
//   encrypted: true,
//   authorizer: (channel) => {
//     return {
//       authorize: (socketId, callback) => {
//         axios({
//           method: 'POST',
//           url: process.env.VUE_APP_HOST_API + '/api/broadcasting/auth',
//           withCredentials: true,
//           data: {
//             socket_id: socketId,
//             channel_name: channel.name,
//           },
//         })
//           .then((response) => {
//             callback(false, response.data)
//           })
//           .catch((error) => {
//             //console.log('Echo authorize error', error)
//             callback(true, error)
//           })
//       },
//     }
//   },
// })

window.ws = ws
app.config.globalProperties.ws = ws
app.config.globalProperties.router = router
store.router = router
store.ws = ws
store.globalProperties = app.config.globalProperties
app.use(store).use(router).use(functions).mount('#app')

import { createRouter, createWebHistory } from 'vue-router'
import LayoutTeam from '@/layouts/Team.vue'
import Loader from '@/views/Loader.vue'
import Home from '@/views/Home.vue'
import NotFound from '@/views/NotFound.vue'
import TeamRegisterRequest from '@/views/team/RegisterRequest.vue'
import TeamRegister from '@/views/team/Register.vue'
import TeamProfile from '@/views/team/Profile.vue'
import KwisStart from '@/views/kwis/Start.vue'
import KwisProgramma from '@/views/kwis/Programma.vue'
import KwisPauze from '@/views/kwis/Pauze.vue'
import KwisVraag from '@/views/kwis/Vraag.vue'
import KwisStand from '@/views/kwis/Stand.vue'
import KwisAfgerond from '@/views/kwis/Afgerond.vue'
import store from '@/store/index'
import SanctumService from '../services/SanctumService'

const authKwisGuard = (to, from, next) => {
  console.log('authKwisGuard')
  if (to.name !== 'Home' && !store.state.kwis.id) {
    return next({ name: 'Home' })
  }
  next()
}

const authTeamGuard = (to, from, next) => {
  console.log('authTeamGuard')
  if (to.name !== 'Home' && !store.state.kwis.id) {
    return next({ name: 'Home' })
  }
  if (!store.state.kwis.team.id) {
    return next({ name: 'KwisStart', params: { url: store.state.kwis.url }})
  }
  next()
}

const routes = [
  {
    path: '/kwis/:url',
    component: LayoutTeam,
    children: [
      {
        name: 'KwisStart',
        path: '',
        component: KwisStart,
        props: true,
      },
    ],
  },
  {
    path: '/',
    component: LayoutTeam,
    children: [
      {
        name: 'Home',
        path: '',
        component: Home,
      },
    ],
    beforeEnter: authKwisGuard,
  },
  {
    path: '/efkes-geduld',
    component: LayoutTeam,
    children: [
      {
        name: 'Loader',
        path: '',
        component: Loader,
      },
    ],
    beforeEnter: authKwisGuard,
  },
  {
    path: '/team/ynskriuwe',
    component: LayoutTeam,
    children: [
      {
        name: 'TeamRegisterRequest',
        path: '',
        component: TeamRegisterRequest,
      },
    ],
    beforeEnter: authKwisGuard,
  },
  {
    path: '/team/:token',
    component: LayoutTeam,
    children: [
      {
        name: 'TeamRegister',
        path: '',
        component: TeamRegister,
        props: true,
      },
    ],
  },
  {
    path: '/team/profyl',
    component: LayoutTeam,
    children: [
      {
        name: 'TeamProfile',
        path: '',
        component: TeamProfile,
      },
    ],
    beforeEnter: authTeamGuard,
  },
  {
    path: '/kwis/programma',
    component: LayoutTeam,
    children: [
      {
        name: 'KwisProgramma',
        path: '',
        component: KwisProgramma,
      },
    ],
    beforeEnter: authTeamGuard,
  },
  {
    path: '/kwis/pauze',
    component: LayoutTeam,
    children: [
      {
        name: 'KwisPauze',
        path: '',
        component: KwisPauze,
      },
    ],
    beforeEnter: authTeamGuard,
  },
  {
    path: '/kwis/vraag',
    component: LayoutTeam,
    children: [
      {
        name: 'KwisVraag',
        path: '',
        component: KwisVraag,
      },
    ],
    beforeEnter: authTeamGuard,
  },
  {
    path: '/kwis/stand',
    component: LayoutTeam,
    children: [
      {
        name: 'KwisStand',
        path: '',
        component: KwisStand,
      },
    ],
    beforeEnter: authTeamGuard,
  },
  {
    path: '/kwis/afgerond',
    component: LayoutTeam,
    children: [
      {
        name: 'KwisAfgerond',
        path: '',
        component: KwisAfgerond,
      },
    ],
    beforeEnter: authTeamGuard,
  },
  {
    path: '/:catchAll(.*)',
    name: 'NotFound',
    component: NotFound,
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

const onPageLoadGetStateFromBackend = () => {
  console.log('onPageLoadGetStateFromBackend start')
  return new Promise((resolve) => {
    SanctumService.csrfCookie().then(() => {
      const promiseTime = store.dispatch('time/set')
      const promiseConfig = store.dispatch('config/set')
      Promise.all([promiseTime, promiseConfig]).then(() => {
        console.log('onPageLoadGetStateFromBackend end')
        resolve()
      })
    })
  })
}

const onPageLoadGetKwisStateFromBackend = () => {
  console.log('onPageLoadGetKwisStateFromBackend start')
  return new Promise((resolve) => {
    SanctumService.csrfCookie().then(() => {
      const promiseTime = store.dispatch('time/set')
      const promiseConfig = store.dispatch('config/set')
      const promiseKwis = store.dispatch('kwis/set', true)
      Promise.all([promiseTime, promiseConfig, promiseKwis]).then(() => {
        console.log('onPageLoadGetKwisStateFromBackend end')
        resolve()
      })
    })
  })
}

router.beforeEach((to, from, next) => {
  console.log('router.beforeEach from', from)
  console.log('router.beforeEach to', to)

  if (store.state.config.loaded) {
    next()
    return
  }
  if (to.name === 'TeamRegister') {
    onPageLoadGetStateFromBackend()
      .then(() => {
        console.log('next()')
        next()
        return
      })
      .catch((e) => {
        console.log(e)
      })
    return
  }
  onPageLoadGetKwisStateFromBackend()
    .then(() => {
      console.log('next()')
      next()
    })
    .catch((e) => {
      console.log(e)
    })
})

export default router

<template>
  <header>
    <svg
      class="background-header"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 1967 697"
      preserveAspectRatio="none"
    >
      <path
        fill-rule="evenodd"
        fill="rgb(255, 204, 78)"
        d="M1966.436,696.230 C1966.436,696.230 1465.945,622.137 983.600,622.137 C501.254,622.137 0.764,696.230 0.764,696.230 L0.764,363.571 L0.764,0.670 L983.600,0.670 L1966.436,0.670 L1966.436,363.571 L1966.436,696.230 Z"
      />
    </svg>
    <div class="logo-icon">
      <img src="/img/logo-icon.png" />
    </div>
    <router-link
      v-if="showProfile"
      title="profyl"
      :to="{ name: 'TeamProfile' }"
      tag="div"
      class="bk-icon bk-icon--pointer right"
    >
      <i class="fa-solid fa-user"></i>
    </router-link>
    <div
      title="Kwis"
      class="bk-icon bk-icon--pointer right"
      v-if="icons.filter((i) => i === 'programma').length"
      @click="kwisStatusRoute()"
    >
      <i class="fa-solid fa-play"></i>
    </div>
  </header>
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: ['icons'],
  computed: {
    ...mapState(['config', 'kwis', 'time']),
    showProfile() {
      return (
        this.icons.filter((i) => i === 'profile').length
      )
    },
  },
  methods: {
    kwisStatusRoute() {
      if (this.kwis.status === 'start') {
        this.$router.push({
          name: 'KwisStart',
          params: { url: this.kwis.url },
        })
      } else if (this.kwis.status === 'programma') {
        this.$router.push({ name: 'KwisProgramma' })
      } else if (this.kwis.status === 'vraag') {
        this.$router.push({ name: 'KwisProgramma' })
      } else if (this.kwis.status === 'antwoord') {
        this.$router.push({ name: 'KwisProgramma' })
      } else if (this.kwis.status === 'resultaat') {
        this.$router.push({ name: 'KwisProgramma' })
      } else if (this.kwis.status === 'stand') {
        this.$router.push({ name: 'KwisProgramma' })
      } else if (this.kwis.status === 'afgerond') {
        this.$router.push({ name: 'KwisAfgerond' })
      } else {
        //should not happen
        this.$router.push({
          name: 'KwisStart',
          params: { url: this.kwis.url },
        })
      }
    },
  },
}
</script>

<style scoped lang="scss">
.background-header {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 85px;
  background-color: #ffeb94;
}

header {
  position: relative;
  height: 85px;
  background-color: #ffcc4e;
  text-align: center;
}

.logo-icon {
  position: absolute;
  width: 87px;
  height: 87px;
  border-radius: 50%;
  background-color: #ffcc4e;
  left: 50%;
  transform: translateX(-50%);
  top: 0px;

  img {
    margin-top: 12px;
    width: 63px;
    height: 63px;
  }
}

.bk-icon {
  position: absolute;
  top: 15px;

  &.right {
    right: 25px;
  }

  &.left {
    left: 25px;
  }
}
</style>

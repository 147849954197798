const state = {
  websockets: false,
  socketId: null,
  kwisSubscribed: false,
  teamSubscribed: false,
}

const mutations = {
  setWebsockets(state, websockets) {
    state.websockets = websockets
  },
  setSocketId(state, socketId) {
    state.socketId = socketId
  },
  setKwisSubscribed(state, subscribed) {
    state.kwisSubscribed = subscribed
  },
  setTeamSubscribed(state, subscribed) {
    state.teamSubscribed = subscribed
  },
}

const actions = {
  setWebsockets({ commit }, websockets) {
    commit('setWebsockets', websockets)
  },
  setSocketId({ commit }, socketId) {
    commit('setSocketId', socketId)
  },
  setKwisSubscribed({ commit }, subscribed) {
    commit('setKwisSubscribed', subscribed)
  },
  setTeamSubscribed({ commit }, subscribed) {
    commit('setTeamSubscribed', subscribed)
  },
}

export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
}

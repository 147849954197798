<template>
  <div class="profile">
    <Header :icons="['programma']"/>
    <FormProfile :register="false" :token="null" :data="null"/>
  </div>
</template>

<script>
import FormProfile from '@/components/team/FormProfile'
import Header from '@/components/Header'

export default {
  props: [],
  components: {
    FormProfile,
    Header,
  }
}
</script>

<template>
  <div>
    <HeaderKlein :icons="['profile']" />
    <Programma />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Programma from '@/components/kwis/Programma'
import HeaderKlein from "@/components/HeaderKlein";

export default {
  components: {
    HeaderKlein,
    Programma,
  },
  computed: {
    ...mapState(['config', 'kwis', 'time']),
  },
  created() {
    if (this.kwis.status !== 'programma') {
      this.$router.push({ name: 'Loader' })
    }
  },
}
</script>

<style lang="scss"></style>

import KwisService from '@/services/KwisService'
import router from '@/router'
import TeamService from '@/services/TeamService'
import BroadcastLogService from '@/services/BroadcastLogService'

const state = () => ({
  id: null,
  status: null,
  plaats: null,
  locatie: null,
  url: null,
  startOp: null,
  programma: [],
  team: { id: null, naam: null, email: null, afbeelding: null, plaats: null },
  vraag: { id: null, status: null, seconden: null, gegevenAntwoord: null },
  gegevenAntwoord: null,
})

const mutations = {
  set(state, kwis) {
    state.id = kwis.id
    state.status = kwis.status
    state.plaats = kwis.plaats
    state.locatie = kwis.locatie
    state.url = kwis.url
    state.startOp = kwis.startOp
    state.programma = kwis.programma
  },
  setTeam(state, team) {
    state.team.id = team ? team.id : null
    state.team.naam = team ? team.naam : null
    state.team.email = team ? team.email : null
    state.team.afbeelding = team ? team.afbeelding : null
    state.team.plaats = team ? team.plaats : null
  },
  setVraag(state, vraag) {
    state.vraag = vraag
  },
  setVraagGegevenAntwoord(state, gegevenAntwoord) {
    state.gegevenAntwoord = gegevenAntwoord
  },
}

const actions = {
  set({ commit, dispatch, rootState }, redirect) {
    return KwisService.get()
      .then((response) => {
        if (response.data.status == 'ok') {
          //vuex
          commit('set', response.data.kwis)
          commit('setTeam', response.data.team)
          //websockets
          if (response.data.kwis.id) {
            if (!rootState.network.kwisSubscribed) {
              dispatch('network/setKwisSubscribed', true, {
                root: true,
              })
              setTimeout(() => {
                this.ws
                  .private('Kwis.' + response.data.kwis.id)
                  .listen('.update', (wsData) => {
                    BroadcastLogService.confirm(
                      wsData.broadcastToken,
                      rootState.network.socketId
                    )
                    commit('set', wsData.kwis)
                  })
                  .listen('.antwoord', (wsData) => {
                    console.log(wsData)
                    if (wsData.confirmToken) {
                      KwisService.antwoordConfirm({
                        confirm_token: wsData.confirmToken,
                        clientside_confirmed_at: new Date().getTime(),
                      })
                        .then((response) => {
                          console.log(response.data)
                          if (response.data.status == 'ok') {
                            if (response.data.message) {
                              dispatch(
                                'notification/add',
                                {
                                  type: 'warning',
                                  message: response.data.message,
                                },
                                {
                                  root: true,
                                }
                              )
                            }
                          }
                        })
                        .catch((error) => {
                          console.log(error)
                        })
                    }
                    BroadcastLogService.confirm(
                      wsData.broadcastToken,
                      rootState.network.socketId
                    )
                    commit('setVraag', wsData.vraag)
                    if (this.router.currentRoute.value.name !== 'TeamProfile') {
                      this.router.push({ name: 'KwisVraag' })
                    }
                  })
              }, 1000)
            }
          }
          if (response.data.team.id) {
            if (!rootState.network.teamSubscribed) {
              dispatch('network/setTeamSubscribed', true, {
                root: true,
              })
              setTimeout(() => {
                this.ws
                  .private('Team.' + response.data.team.id)
                  .listen('.start', (wsData) => {
                    BroadcastLogService.confirm(
                      wsData.broadcastToken,
                      rootState.network.socketId
                    )
                    if (this.router.currentRoute.value.name !== 'TeamProfile') {
                      this.router.push({
                        name: 'KwisStart',
                        params: { url: response.data.kwis.url },
                      })
                    }
                  })
                  .listen('.programma', (wsData) => {
                    BroadcastLogService.confirm(
                      wsData.broadcastToken,
                      rootState.network.socketId
                    )
                    commit('set', wsData.kwis)
                    if (this.router.currentRoute.value.name !== 'TeamProfile') {
                      this.router.push({ name: 'KwisProgramma' })
                    }
                  })
                  .listen('.pauze', (wsData) => {
                    BroadcastLogService.confirm(
                      wsData.broadcastToken,
                      rootState.network.socketId
                    )
                    if (this.router.currentRoute.value.name !== 'TeamProfile') {
                      this.router.push({ name: 'KwisPauze' })
                    }
                  })
                  .listen('.media', (wsData) => {
                    BroadcastLogService.confirm(
                      wsData.broadcastToken,
                      rootState.network.socketId
                    )
                    commit('setVraag', wsData.vraag)
                    if (this.router.currentRoute.value.name !== 'TeamProfile') {
                      this.router.push({ name: 'KwisVraag' })
                    }
                  })
                  .listen('.vraag', (wsData) => {
                    BroadcastLogService.confirm(
                      wsData.broadcastToken,
                      rootState.network.socketId
                    )
                    commit('setVraag', wsData.vraag)
                    if (this.router.currentRoute.value.name !== 'TeamProfile') {
                      this.router.push({ name: 'KwisVraag' })
                    }
                  })
                  .listen('.resultaatinfo', (wsData) => {
                    BroadcastLogService.confirm(
                      wsData.broadcastToken,
                      rootState.network.socketId
                    )
                    commit('setVraag', wsData.vraag)
                    if (this.router.currentRoute.value.name !== 'TeamProfile') {
                      this.router.push({ name: 'KwisVraag' })
                    }
                  })
                  .listen('.resultaat', (wsData) => {
                    BroadcastLogService.confirm(
                      wsData.broadcastToken,
                      rootState.network.socketId
                    )
                    commit('setVraag', wsData.vraag)
                    commit(
                      'setVraagGegevenAntwoord',
                      wsData.vraag.gegevenAntwoord
                    )
                    if (this.router.currentRoute.value.name !== 'TeamProfile') {
                      this.router.push({ name: 'KwisVraag' })
                    }
                  })
                  .listen('.stand', (wsData) => {
                    BroadcastLogService.confirm(
                      wsData.broadcastToken,
                      rootState.network.socketId
                    )
                    commit('setTeam', wsData.team)
                    if (this.router.currentRoute.value.name !== 'TeamProfile') {
                      this.router.push({ name: 'KwisStand' })
                    }
                  })
                  .listen('.afgerond', (wsData) => {
                    BroadcastLogService.confirm(
                      wsData.broadcastToken,
                      rootState.network.socketId
                    )
                    commit('setTeam', wsData.team)
                    if (this.router.currentRoute.value.name !== 'TeamProfile') {
                      this.router.push({ name: 'KwisAfgerond' })
                    }
                  })
              }, 1000)
            }
          }
          //redirect
          console.log('redirect', redirect)
          if (redirect) {
            console.log('redirect')
            if (response.data.kwis.status === 'start') {
              router.push({
                name: 'KwisStart',
                params: { url: response.data.kwis.url },
              })
            } else if (response.data.kwis.status === 'programma') {
              router.push({ name: 'KwisProgramma' })
            } else if (response.data.kwis.status === 'pauze') {
              router.push({ name: 'KwisPauze' })
            } else if (response.data.kwis.status === 'vraag') {
              router.push({ name: 'KwisProgramma' })
            } else if (response.data.kwis.status === 'antwoord') {
              router.push({ name: 'KwisProgramma' })
            } else if (response.data.kwis.status === 'resultaatinfo') {
              router.push({ name: 'KwisProgramma' })
            } else if (response.data.kwis.status === 'resultaat') {
              router.push({ name: 'KwisProgramma' })
            } else if (response.data.kwis.status === 'stand') {
              router.push({ name: 'KwisStand' })
            } else if (response.data.kwis.status === 'afgerond') {
              router.push({ name: 'KwisAfgerond' })
            } else {
              //should not happen
              router.push({
                name: 'KwisStart',
                params: { url: response.data.kwis.url },
              })
            }
          }
        }
      })
      .catch((error) => {
        console.log(error)
      })
  },
  unset({ commit }) {
    commit('set', { id: null })
  },
  unregister({ commit, dispatch }) {
    TeamService.unregister()
      .then((response) => {
        if (response.data.status == 'ok') {
          if (response.data.message) {
            dispatch(
              'notification/add',
              {
                type: 'success',
                message: response.data.message,
              },
              {
                root: true,
              }
            )
          }
        }
        dispatch('set', true)
      })
      .catch((error) => {
        console.log(error)
      })
  },
  setVraagGegevenAntwoord({ commit, state }, data) {
    let currentGegevenAntwoord = state.gegevenAntwoord
    commit('setVraagGegevenAntwoord', data.antwoord)
    KwisService.antwoord({
      programma_vraag_id: data.vraagId,
      antwoord_id: data.antwoord.id,
      clientside_answered_at: new Date().getTime(),
    }).then((response) => {
      if (response.data.status == 'error') {
        commit('setVraagGegevenAntwoord', currentGegevenAntwoord)
      }
    })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}

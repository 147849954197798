<template>
  <div class="time-counter">
    {{ counter }}
  </div>
</template>

<script>
export default {
  props: ['timelineDurationSeconds', 'timelineProgressSeconds'],
  computed: {
    counter() {
      return this.timelineDurationSeconds - this.timelineProgressSeconds
    },
  },
}
</script>

<style scoped lang="scss">
.time-counter {
  font-family: AmsiProCondensed-Black;
  width: 57px;
  height: 57px;
  background-color: #ffcc4e;
  text-align: center;
  font-size: 40px;
  line-height: 40px;
  border-radius: 50%;
  color: #dd131c;
  padding-top: 5px;
}
</style>

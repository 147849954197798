export default {
  install: (app) => {
    app.config.globalProperties.$dateFormat = (d) => {
      let date = new Date(d)
      return new Intl.DateTimeFormat('nl-NL', { dateStyle: 'full', timeStyle: 'long' }).format(date)
    }
    app.config.globalProperties.$date = (d) => {
      return new Date(d)
    }
    app.config.globalProperties.$timestamp = (d) => {
      return d.getTime() / 1000
    }
  },
}

<template>
  <router-link v-if="kwis.team.id" title="Profyl" :to="{ name: 'TeamProfile' }"
    >Profyl
  </router-link>
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: [],
  computed: {
    ...mapState(['kwis']),
  },
}
</script>

<style scoped lang="scss"></style>

const state = {
  dirty: false,
}

const mutations = {
  setDirty(state, dirty) {
    state.dirty = dirty
  },
}

const actions = {
  setDirty({ commit }, dirty) {
    commit('setDirty', dirty)
  },
}

export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
}

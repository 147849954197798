<template>
  <button v-if="kwis.team.id" class="bk-btn bk-btn--secondary" @click.stop="unregister()">Kwis ferlitte</button>
  <confirm-dialogue ref="confirmDialogue"></confirm-dialogue>
</template>

<script>
import { mapState } from 'vuex'
import ConfirmDialogue from '@/components/ConfirmDialogue'

export default {
  props: [],
  components: { ConfirmDialogue },
  computed: {
    ...mapState(['kwis']),
  },
  methods: {
    async unregister() {
      const confirm = await this.$refs.confirmDialogue.show({
        title: 'Kwis ferlitte?',
        message: 'Ast fuortgiest wurde al dyn gegevens yn dizze kroechkwis fuorthelle.',
        confirmButton: 'Ja, ferlitte',
      })
      if (confirm) {
        this.$store.dispatch('kwis/unregister')
        this.$store.dispatch('network/setKwisSubscribed', false)
        this.$store.dispatch('network/setTeamSubscribed', false)
      }
    },
  },
}
</script>
